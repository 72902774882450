import {
  Box,
  Button,
  CheckFilledIcon,
  Dialog,
  Flex,
  HelperMessage,
  LoadingIcon,
  Notification,
  TextInput,
  useToast,
} from '@iheartradio/web.companion';
import { isFunction, isNil } from '@iheartradio/web.utilities';
import { Link as RouterLink, useFetcher } from '@remix-run/react';
import {
  type ReactNode,
  type Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { route } from 'routes-gen';

import type {
  CreateCollectionAction,
  CreateCollectionActionData,
} from '~app/routes/api.v1.collection.create';
import { INPUT_MAX_LENGTH_DEFAULT } from '~app/utilities/constants';
import { buildPlaylistUrl } from '~app/utilities/urls';

export type CreatePlaylistSubmitCallback = (
  playlist: CreateCollectionActionData,
  closeDialog: () => void,
) => void;

// Either the `albumId` or `tracks` key should be set, but not both.
export type CreatePlaylistDialogProps = {
  onSubmit?: CreatePlaylistSubmitCallback;
  setMenuOpen?: (open: boolean) => void;
  trigger: ReactNode;
  albumId?: number;
  tracks?: number[];
};

export const CreatePlaylistDialog = (props: CreatePlaylistDialogProps) => {
  const { onSubmit, trigger, setMenuOpen, tracks, albumId, ...restProps } =
    props;

  const [open, setOpen] = useState(false);
  const [playlistNameIsValid, setPlaylistNameIsValid] = useState(false);
  const ref = useRef<HTMLFormElement>();
  const [characterCount, setCharacterCount] = useState(0);

  const toast = useToast();
  const createCollection = useFetcher<CreateCollectionAction>();

  const isSubmitting = createCollection.state === 'submitting';
  const isLoading = createCollection.state === 'loading';
  const isDone =
    createCollection.state === 'idle' && createCollection.data != null;
  const isSuccessful = isDone && createCollection.data?.ok;

  const errorMessage = createCollection.data?.ok
    ? null
    : createCollection.data?.errorMessage;

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback<CreatePlaylistSubmitCallback>(
    (data, closeDialog) => {
      if (data.ok) {
        closeDialog();

        toast(dismiss => (
          <Notification
            icon={<CheckFilledIcon fill="green-600" />}
            kind="success"
            onDismiss={dismiss}
          >
            Playlist created
            <Flex
              alignItems="center"
              flexDirection="row"
              gap="$8"
              justifyContent="flex-end"
            >
              <Button
                as={RouterLink}
                color="gray"
                kind="tertiary"
                size="large"
                to={buildPlaylistUrl(data.collection)}
              >
                Go to playlist
              </Button>
            </Flex>
          </Notification>
        ));
      }

      setMenuOpen?.(false);
    },
    [],
  );

  // Reset the form on submission
  useEffect(() => {
    if (isSuccessful) {
      ref?.current?.reset();
    }
  }, [isSuccessful]);

  useEffect(() => {
    if (isDone && createCollection?.data) {
      isFunction(onSubmit)
        ? onSubmit(createCollection.data, closeDialog)
        : handleSubmit(createCollection?.data, closeDialog);
    }
  }, [createCollection, closeDialog, isDone]);

  return (
    <Dialog.Root
      onOpenChange={setOpen}
      open={open}
      trigger={trigger}
      {...restProps}
    >
      <createCollection.Form
        action={route('/api/v1/collection/create')}
        method="POST"
        ref={ref as Ref<HTMLFormElement>}
      >
        <Dialog.Metadata>
          <Dialog.Title>Create New Playlist</Dialog.Title>
          <Box>
            <TextInput
              label="Playlist Name"
              maxLength={INPUT_MAX_LENGTH_DEFAULT}
              name="name"
              onChange={value => {
                setCharacterCount(value.length);
                setPlaylistNameIsValid(value.trim() !== '');
              }}
              placeholder="Enter playlist name"
              required
            />
            {isNil(albumId) && tracks ? (
              tracks.map((trackId, index) => (
                <input
                  key={trackId}
                  name={`tracks[${index}]`}
                  type="hidden"
                  value={trackId}
                />
              ))
            ) : albumId && !tracks?.length ? (
              <input name="albumId" type="hidden" value={albumId} />
            ) : null}
            <HelperMessage kind="neutral" padding="$4 $0 $0 $0">
              {characterCount} / {INPUT_MAX_LENGTH_DEFAULT}
            </HelperMessage>
          </Box>
          {errorMessage ? (
            <HelperMessage key={errorMessage} kind="error">
              {errorMessage}
            </HelperMessage>
          ) : null}
          <Dialog.ButtonContainer flexDirection="row">
            <Button
              color={{ dark: 'white', light: 'gray' }}
              inline
              kind="secondary"
              onClick={() => {
                ref?.current?.reset();
                setOpen(false);
              }}
              size={{ '@xsmall': 'small', '@medium': 'large' }}
              type="submit"
            >
              Cancel
            </Button>
            <Button
              color="red"
              disabled={isSubmitting || isLoading || !playlistNameIsValid}
              inline
              kind="primary"
              size={{ '@xsmall': 'small', '@medium': 'large' }}
              type="submit"
            >
              {createCollection.state === 'idle' ? (
                'Create playlist'
              ) : (
                <LoadingIcon size="16" />
              )}
            </Button>
          </Dialog.ButtonContainer>
        </Dialog.Metadata>
      </createCollection.Form>
    </Dialog.Root>
  );
};
